import CallApp from "callapp-lib";
import { platform } from "@/utils/util";
import { IObjectKeys } from "@/types/common";
import constants from "./constants";
// 插件处于初始化阶段，后续可考虑替换掉

// ios端唤起app配置
const iosOption: any = {
  universal: {
    host: "oia-pan-ssl.xunlei.com",
    pathKey: "query",
  },
  appstore: "//apps.apple.com/cn/app/id1503466530",
};

const callIosApp = function ({
  target = "app",
  action = "switchTab",
  params = {},
  from = "",
} = {}) {
  const platEnv = platform();
  params = Object.entries(params)
    .map(([key, value]) => `${key}=${value}`)
    .join("&");

  if (platEnv === "qq" || platEnv === "weibo") {
    // qq，微博环境，无法直接使用universval link，增加中间页处理
    iosOption.universal.host = "oia-pan-ssl.xunlei.com/guide-download";
  } else {
    iosOption.universal.host = "oia-pan-ssl.xunlei.com";
  }

  const callAppIos = new CallApp(iosOption);
  // 通过universal link唤起ios app
  // 这个插件目前不能很好的判断ios环境，所以只通过他获取链接，自己跳转
  const universalUrl = callAppIos.generateUniversalLink({
    path: encodeURIComponent(`xunlei://${target}/${action}?${params}`),
  });
  window.location.href = universalUrl + (from && `&ct=${from}`);
};

// 安卓端唤起app配置
const option: any = {
  scheme: {
    protocol: "xunleiapp",
  },
  // intent: {
  //   package: '',
  //   scheme: 'xunleiapp'
  // },
  // appstore: 'http://oia-pan-ssl.xunlei.com/',
  // yingyongbao: constants.YINGYONGBAK_SHARE_DOWNLOAD_URL,
  // fallback: 'http://zhan.xunlei.com/',
  timeout: 2000,
};
const callApp = new CallApp(option);

const downloadAndroidApkUrl: IObjectKeys = {
  share: "http://m.down.sandai.net/mobile/MobileThunder_zhan.apk",
  home: "http://m.down.sandai.net/mobile/MobileThunder_zhan.apk",
};

// 唤起安卓app失败- 去下载
const downloadAndroidApk = (page_type: string) => {
  window.location.href = downloadAndroidApkUrl[page_type];
};

export { callIosApp, callApp, downloadAndroidApk, CallApp };
