import store from "store";
import { IFeedItem } from "@/types/post";
import eventEmitter from "@/utils/eventEmitter";
import xlClientMixin from "@/mixins/pc-client";
import macClientMixin from "@/mixins/mac-client";
import { detectOS, checkIOS, base64encode, isMacThunder } from "@/utils/util";
import constants from "@/utils/constants";
import {
  batchGetFileInfo,
  batchGetShareFileInfo,
  getFileInfo,
} from "@/api/drive";
import eventMap from "@/utils/event-name";

export default {
  mixins: [xlClientMixin, macClientMixin],
  filters: {
    displayActionText(info: IFeedItem) {
      let text = "转存云盘";
      if (info.payment === 2) {
        text = "会员下载";
      }

      if (info.partner === "tuguaishou") {
        text = "在线编辑";
      }

      return text;
    },
  },
  data() {
    return {
      checkRestoreId: "",
      withCaptcha: false,
      downloadClicked: false,
    };
  },
  computed: {
    curUser() {
      return this.$store.state.user.curUser;
    },
    hasLogin() {
      return this.curUser.userId !== "0";
    },
    isVip() {
      return this.curUser.vipData && this.curUser.vipData.isVip;
    },
    isIOS() {
      return process.client ? checkIOS() : false;
    },
    platform() {
      return this.$store.state.platform;
    },
  },
  methods: {
    stat(action: string, data: any): void {
      this.$stat("xiaozhan", action, data);
    },
    async handleSave(
      item: IFeedItem,
      fileIds: any = null,
      ancestorIds: string[] = [],
      page = "home",
      checkAll = true,
      from = "home",
      extra = { pid: "", zone_name: "" }
    ): Promise<void> {
      if (!this.hasLogin) {
        this.popLogin();
        return;
      }

      const pid = extra.pid;
      let eventName = `collect-result-${item.id}`;
      if (pid) {
        eventName += `-${pid}`;
      }

      // v1.12.0 收藏不触发转存
      try {
        const result = await this.$store.dispatch("post/favoriteFeed", {
          resource_id: item.id,
        });

        this.stat("xiaozhan_collect_result", {
          from,
          position: page !== "detail" ? "card" : "detail_page",
          is_collect_all_file: checkAll ? 1 : 0,
          is_login: this.hasLogin ? 1 : 0,
          upUid: item.user_info ? item.user_info.user_id : "",
          result: "success",
          cardID: item.id,
          zone_name: extra.zone_name,
        });

        const saveSuccessDialogVisible = store.get(
          "save_success_dialog_visible"
        );

        eventEmitter.emit(
          `save-success-${page}`,
          0,
          !saveSuccessDialogVisible ? "dialog" : "toast"
        );

        eventEmitter.emit(eventName, 0, true);
      } catch (e) {
        if (e.error && e.error === "resource_not_found") {
          this.$message({
            type: "warning",
            message: "该资源已失效",
          });
        }

        if (e.error && e.error === "already_exists") {
          eventEmitter.emit(eventName, 0, true);
        }

        this.stat("xiaozhan_collect_result", {
          from,
          position: page !== "detail" ? "card" : "detail_page",
          is_collect_all_file: checkAll ? 1 : 0,
          is_login: this.hasLogin ? 1 : 0,
          upUid: item.user_info ? item.user_info.user_id : "",
          result: "fail",
          errorcode: e.error,
          cardID: item.id,
          zone_name: extra.zone_name,
        });

        // this.$message({
        //   message: e.error_description || "收藏失败，请稍后重试",
        //   type: "warning",
        // });
      }

      return;

      const params: any = {
        share_id: item.source_id,
        pass_code_token: "",
        ancestor_ids: ancestorIds, // 转存文件的从根目录开始的祖先文件id列表
        file_ids: fileIds, // 转存文件id列表
        parent_id: "", // 转存至指定文件夹
        folder_type: "ZHAN",
      };

      this.$store
        .dispatch("share/postRestore", params)
        .then(async (res: any) => {
          if (res.restore_status === "RESTORE_COMPLETE") {
            this.$store.commit("share/setSaveFolderId", res.file_id);
            const saveSuccessDialogVisible = store.get(
              "save_success_dialog_visible"
            );
            eventEmitter.emit(
              `save-success-${page}`,
              0,
              !saveSuccessDialogVisible ? "dialog" : "toast"
            );

            this.stat("xiaozhan_collect_result", {
              from,
              position: page !== "detail" ? "card" : "detail_page",
              is_collect_all_file: checkAll ? 1 : 0,
              is_login: this.hasLogin ? 1 : 0,
              upUid: item.user_info ? item.user_info.user_id : "",
              result: "success",
              cardID: item.id,
            });

            try {
              const result = await this.$store.dispatch("post/favoriteFeed", {
                resource_id: item.id,
              });
            } catch (e) {
              if (e.error && e.error === "resource_not_found") {
                this.$message({
                  type: "warning",
                  message: "该资源已失效",
                });
              }
            }
          } else if (res.restore_status === "RESTORE_START") {
            this.checkRestoreId = res.restore_task_id;
            this.$store.commit("share/setSaveFolderId", res.file_id);
            setTimeout(
              () => this.checkRestoreStatus(0, item, page, checkAll, from),
              1000
            );
          } else {
            this.$message({
              message: `转存失败 ${res.restore_status}  ${res.share_status}`,
              type: "warning",
            });

            this.stat("xiaozhan_collect_result", {
              from,
              position: page !== "detail" ? "card" : "detail_page",
              is_collect_all_file: checkAll ? 1 : 0,
              is_login: this.hasLogin ? 1 : 0,
              upUid: item.user_info ? item.user_info.user_id : "",
              result: "fail",
              errorcode: res.restore_status,
              cardID: item.id,
            });

            if (this.shareStatus !== 0) return;
          }
        })
        .catch((err: any) => {
          this.stat("xiaozhan_collect_result", {
            from,
            position: page !== "detail" ? "card" : "detail_page",
            is_collect_all_file: checkAll ? 1 : 0,
            is_login: this.hasLogin ? 1 : 0,
            upUid: item.user_info ? item.user_info.user_id : "",
            result: "fail",
            errorcode: err.error_code,
            cardID: item.id,
          });

          this.$message({
            message: err.error_description || "收藏失败，请稍后重试",
            type: "warning",
          });
        });
    },
    checkRestoreStatus(
      index = 0,
      item: IFeedItem,
      page = "home",
      checkAll = true,
      from = "home"
    ): void {
      const ms = [1, 3, 5, 10];
      const m = ms[index];
      this.checkRestoreTimer = setTimeout(() => {
        this.$store
          .dispatch("share/checkRestoreStatus", this.checkRestoreId)
          .then(async (res: any) => {
            if (res.phase === "PHASE_TYPE_ERROR") {
              this.$message({
                message: res.message || "收藏失败，请稍后重试",
                type: "warning",
              });

              this.stat("xiaozhan_collect_result", {
                from,
                position: page !== "detail" ? "card" : "detail_page",
                is_collect_all_file: checkAll ? 1 : 0,
                is_login: this.hasLogin ? 1 : 0,
                upUid: item.user_info ? item.user_info.user_id : "",
                result: "fail",
                errorcode: res.params ? res.params.error_detail : res.phase,
                cardID: item.id,
              });
            } else if (res.phase === "PHASE_TYPE_COMPLETE") {
              const saveSuccessDialogVisible = store.get(
                "save_success_dialog_visible"
              );
              eventEmitter.emit(
                `save-success-${page}`,
                0,
                !saveSuccessDialogVisible ? "dialog" : "toast"
              );

              this.stat("xiaozhan_collect_result", {
                from,
                position: page !== "detail" ? "card" : "detail_page",
                is_collect_all_file: checkAll ? 1 : 0,
                is_login: this.hasLogin ? 1 : 0,
                upUid: item.user_info ? item.user_info.user_id : "",
                result: "success",
                cardID: item.id,
              });

              try {
                const result = await this.$store.dispatch("post/favoriteFeed", {
                  resource_id: item.id,
                });
              } catch (e) {
                if (e.error && e.error === "resource_not_found") {
                  this.$message({
                    type: "warning",
                    message: "该资源已失效",
                  });
                }
              }
            } else {
              this.checkRestoreStatus(
                index < 3 ? index + 1 : 3,
                item,
                page,
                checkAll,
                from
              );
            }
          })
          .catch((err: any) => {
            this.checkRestoreStatus(index, item, page, checkAll, from);
          });
      }, m * 1000);
    },
    genFeedStatData(
      list: IFeedItem[],
      page = "home",
      category = "",
      clickid = "",
      start = 0,
      displayStyle = "card"
    ): void {
      const statData: any = {
        pageType: page,
        contentType: encodeURIComponent(category),
      };

      let action = "xiaozhan_card_show";
      const timestamp = new Date().getTime();
      if (!clickid) {
        const contentList = list
          .map((item: IFeedItem, index: number): string => {
            if (!item) {
              return "";
            }
            // compatability
            let visit = "0";
            let comment = "0";
            let download = "0";
            if (item.view_count) {
              visit = item.view_count;
            }

            if (item.download_count) {
              comment = item.download_count;
            }

            if (item.comment_count) {
              download = item.comment_count;
            }

            const filenum = item.files ? item.files.length : 0;
            const userId = item.user_id
              ? item.user_id
              : (item.user_info && item.user_info.user_id) || "";
            const needVip = item.payment === 2 ? 1 : 0;
            const resourceType = item.partner || "normal";
            const rawStatData = {
              cardID: item.id,
              // rn: index + 1 + start,
              title: encodeURIComponent(item.title),
              filesize: item.size || 0,
              filenum: filenum,
              upUid: userId,
              sessionid: timestamp,
              style: displayStyle,
              visit: visit,
              comment: comment,
              download: download,
              need_vip: needVip,
              resource_type: resourceType,
              zone_name: encodeURIComponent(category),
            };

            return Object.keys(rawStatData)
              .map((sk: string) => `${sk}=${rawStatData[sk]}`)
              .join(",");
          })
          .join(";");
        statData.contenlist = encodeURIComponent(`{${contentList}}`);
      } else {
        statData.clickid = clickid;
        if (list && list[0]) {
          const rawExtra = list[0];
          statData.cardID = rawExtra.id;
          statData.rn = 1 + start;
          statData.title = encodeURIComponent(rawExtra.title);
          statData.filesize = rawExtra.size || 0;
          statData.filenum = rawExtra.files ? rawExtra.files.length : 0;
          statData.upUid = rawExtra.user_id
            ? rawExtra.user_id
            : (rawExtra.user_info && rawExtra.user_info.user_id) || "";
          statData.sessionid = timestamp;
          statData.style = displayStyle;
          statData.need_vip = rawExtra.payment === 2 ? 1 : 0;
          statData.zone_name = encodeURIComponent(category);
          statData.resource_type = rawExtra.partner || "normal";
        }

        action = "xiaozhan_card_click";
      }

      this.stat(action, statData);
    },
    async handleDownload(
      info: IFeedItem,
      from = "",
      position = "card",
      selectedFiles = [],
      extraStat = {}
    ) {
      let rawFiles = info.files;
      let shareId = info.source_id || info.share_id;

      if (info.payment === 2) {
        if (!this.hasLogin) {
          this.popLogin("download");
          return;
        }

        if (!this.isVip) {
          eventEmitter.emit(eventMap.SHOW_PAY_DIALOG, 0, {});
          return;
        }
      }

      if (!shareId) {
        this.$message({
          message: "无法获取文件信息，请稍后重试",
          type: "error",
        });
        return;
      }

      if (shareId) {
        shareId = shareId.split("").reverse().join("");
      }

      // 主动获取files
      if (!info.files || info.files.length === 0) {
        try {
          const shareInfoParams = {
            share_id: shareId,
            pass_code: "",
            limit: 100,
            pass_code_token: "",
            page_token: "",
          };

          const shareInfoRes = await this.$store.dispatch("share/getShare", {
            params: shareInfoParams,
            withCaptcha: true,
            withCredentials: this.hasLogin,
          });

          this.withCaptcha = true;

          rawFiles = shareInfoRes.files;

          if (shareInfoRes.share_status !== "OK") {
            this.$message({
              message: "暂时无法下载，请稍后重试",
              type: "error",
            });
            return;
          }
        } catch (err) {
          this.$message({
            message: err,
            type: "error",
          });
          return;
        }
      }

      if (this.hasLogin && rawFiles.length === 0) {
        this.$message({
          type: "warning",
          message: "下载失败，文件已被移除",
        });
        return;
      }

      const platform: any = process.client ? detectOS() : "Windows";

      // 适配百度文库资源，策略调整为免费资源统一跳转云盘分享转存
      if (
        info.payment !== 2 &&
        (platform === "macOS" || platform === "Windows")
      ) {
        window.location.href = `https://pan.xunlei.com/s/${shareId}?entryfrom=xiaozhan`;
        return;
      }

      if (platform === "macOS") {
        try {
          const statData = {
            from: from,
            position,
            is_collect_all_file: 1,
            is_login: this.hasLogin,
            upUid: info.user_info ? info.user_info.user_id : "",
            result: "success",
            cardID: info.id,
            need_vip: info.payment === 2 ? 1 : 0,
            ...extraStat,
          };

          this.macThunderDownload(shareId, rawFiles, statData);
          this.downloadClicked = false;
        } catch (e) {
          this.downloadClicked = false;
        }
      }

      const {
        CallApp,
        callIosApp,
        downloadAndroidApk,
      } = require("@/utils/call-app");

      if (this.isIOS) {
        const {
          isIOSNative,
          sendMessage,
        } = require("@xunlei/universal-native-api");

        if (isIOSNative) {
          sendMessage({
            platform: "ios",
            name: "xunleiQuery",
            params: {
              target: "cloud",
              action: "share",
              params: {
                share_id: shareId,
                pass_code_token: "",
              },
            },
          }).then((resp) => {
            const [err, result] = resp;
            console.log("iOS转存 err, result :>> ", err, result);
          });
        } else {
          callIosApp({
            target: "cloud",
            action: "share",
            params: {
              share_id: shareId,
              pass_code_token: "",
            },
            from: "xiaozhan",
          });
        }
      }

      if (platform === "android") {
        const callApp = new CallApp({
          scheme: {
            protocol: "xunleiapp",
          },
          yingyongbao: constants.YINGYONGBAK_SHARE_DOWNLOAD_URL,
          timeout: 2000,
        });

        callApp.open({
          param: {
            share_id: shareId,
            from: "xiaozhan",
            code: "",
            user_id: this.curUser.userId,
          },
          path: "xunlei.com/xlpan/share/restore",
          callback: () => {
            downloadAndroidApk("share");
          },
        });

        // callApp.open({
        //   param: {
        //     share_id: shareId,
        //     folder_id: "",
        //     pass_code_token: encodeURIComponent(""),
        //     user_id: this.curUser.userId,
        //   },
        //   path: "xunlei.com/xlpan/share/download",
        //   callback: () => {
        //     downloadAndroidApk("share");
        //   },
        // });
      }

      if (platform === "Windows") {
        if (this.downloadClicked) {
          return;
        }
        this.downloadClicked = true;

        try {
          const files = selectedFiles.length ? selectedFiles : rawFiles;
          const shareUserId = info.user_info
            ? info.user_info.user_id || ""
            : "";
          const params = {
            files,
            userId: this.curUser.userId,
            fromShare: true,
            shareId,
            passCodeToken: "",
            shareUserId,
            from: "xiaozhan",
            needLogin: false,
            selectNav: window.native ? "find-page" : "",
          };
          console.log("handleDownload params >>>", params);
          const callClientRetrieveRes = await this.callClientRetrieve(params);
          console.log(
            "handleDownload callClientRetrieveRes >>>",
            callClientRetrieveRes
          );

          if (callClientRetrieveRes.result === "fail") {
            eventEmitter.emit("feed-download-fail", 0, {
              ...info,
              files: rawFiles,
            });
            const statData = {
              from: from,
              position,
              is_collect_all_file: 1,
              is_login: this.hasLogin,
              upUid: info.user_info ? info.user_info.user_id : "",
              result: "fail",
              errorcode: callClientRetrieveRes.code,
              cardID: info.id,
              need_vip: info.payment === 2 ? 1 : 0,
              ...extraStat,
            };

            this.stat("xiaozhan_download_result", statData);
            setTimeout(() => {
              this.downloadClicked = false;
            }, 300);
            return;
          }

          // 上报用户下载次数
          this.$store.dispatch("post/reportUserDownload", {
            resource_id: info.id,
          });

          this.stat("xiaozhan_download_result", {
            from: from,
            position,
            is_collect_all_file: 1,
            is_login: this.hasLogin,
            upUid: info.user_info ? info.user_info.user_id : "",
            result: "success",
            cardID: info.id,
            need_vip: info.payment === 2 ? 1 : 0,
            ...extraStat,
          });
          setTimeout(() => {
            this.downloadClicked = false;
          }, 300);
        } catch (e) {
          eventEmitter.emit("feed-download-fail", 0, {
            ...info,
            files: rawFiles,
          });

          this.stat("xiaozhan_download_result", {
            from: from,
            position,
            is_collect_all_file: 1,
            is_login: this.hasLogin,
            upUid: info.user_info ? info.user_info.user_id : "",
            result: "fail",
            errorcode: e,
            cardID: info.id,
            need_vip: info.payment === 2 ? 1 : 0,
            ...extraStat,
          });
          setTimeout(() => {
            this.downloadClicked = false;
          }, 300);
        }
      }
    },
    async macThunderDownload(shareId: string, files: any[], statData: any) {
      try {
        if (this.downloadClicked) {
          return;
        }
        this.downloadClicked = true;

        const folderIds = files
          .filter((item) => item.kind === "drive#folder")
          .map((item) => item.id);
        const batchRequest: any[] = [];

        for (let fid of folderIds) {
          const params = {
            share_id: shareId,
            parent_id: fid,
            pass_code_token: "",
            limit: 100,
            page_token: "",
          };

          batchRequest.push(
            this.$store.dispatch("share/getDetail", {
              params,
              withCredentials: this.hasLogin,
            })
          );
        }
        const folderInfoRes = await Promise.all(batchRequest);
        const rawSecondLevelIds = folderInfoRes
          .filter((info) => {
            if (info.share_status === "OK" && info.files) {
              return true;
            }
            return false;
          })
          .map((info) => {
            return info.files
              .filter(
                (file: any) =>
                  file.kind === "drive#file" &&
                  file.audit &&
                  file.audit.status === "STATUS_OK"
              )
              .map((file: any) => file.id);
          });
        const secondLevelIds = [].concat(...rawSecondLevelIds);
        // 此处只做二级目录查询
        const firstLevelIds = files
          .filter((item) => item.kind === "drive#file")
          .map((item) => item.id);
        const ids = [...firstLevelIds, ...secondLevelIds];

        // 接口数量限制100
        if (ids.length > 100) {
          this.$message({
            type: "warning",
            message: "任务数过多，创建失败",
          });

          return;
        }

        const fileInfoRes = await batchGetShareFileInfo({
          share_id: shareId,
          file_ids: ids,
        });

        const unaudited =
          fileInfoRes.files &&
          fileInfoRes.files.some((file: any) => {
            return (
              file.audit &&
              ["STATUS_SENSITIVE_RESOURCE", "STATUS_SENSITIVE_WORD"].includes(
                file.audit.status
              )
            );
          });

        if (unaudited) {
          this.$message({
            type: "warning",
            message: "包含违规内容, 创建失败",
          });
          return;
        }

        const tasks =
          fileInfoRes.files &&
          fileInfoRes.files
            .map(({ links, name, icon_link, size, id, kind }: any) => {
              return {
                name,
                url:
                  links["application/octet-stream"] &&
                  links["application/octet-stream"].url,
                id,
                kind,
                size,
                icon_link,
              };
            })
            .filter((item: any) => !!item.url);

        if (!tasks || tasks.length === 0) {
          this.$message({
            type: "warning",
            message: "暂时无法获取下载链接，请稍后重试",
          });
          return;
        }

        if (isMacThunder()) {
          const { macApi } = require("@xunlei/universal-native-api");
          const infoRes = await macApi.call("getPlatformInfo", {});

          if (
            infoRes &&
            infoRes.clientVersion &&
            infoRes.clientVersion >= this.macDirectDownloadClientVersion
          ) {
            await macApi.call("createDownloadTasks", {
              tasks,
              options: {
                isPrivate: true,
                isSilent: false,
              },
            });

            this.stat("xiaozhan_download_result", statData);
          } else {
            this.createMacTasks({
              downloadDir: "",
              tasks,
            });
            this.stat("xiaozhan_download_result", statData);
          }
        } else {
          this.createMacTasks({
            downloadDir: "",
            tasks,
          });
          this.stat("xiaozhan_download_result", statData);
        }

        // 上报用户下载次数
        this.$store.dispatch("post/reportUserDownload", {
          resource_id: statData.cardID,
        });
      } catch (error) {
        console.error(error);
        this.$message({
          type: "warning",
          message: "创建失败",
        });
        this.stat("xiaozhan_download_result", {
          ...statData,
          result: "fail",
          errorcode: error,
        });
      }
    },
    async jumpThirdParyUrl(info) {
      // TODO: 暂时前置登录态，解决小站被三方iframe嵌入，sso sdk授权失败
      // if (!this.hasLogin) {
      //   this.popLogin("edit");
      //   return;
      // }

      try {
        const params = {
          partner: info.partner,
          tid: info.tid,
          rid: info.id,
          is_login: this.hasLogin,
        };
        const result = await this.$store.dispatch(
          "post/fetchThirdPartyJumpUrl",
          params
        );

        if (result.url) {
          // window.open(result.url, "_blank", "noopener,noreferrer");
          if (isMacThunder()) {
            const { macApi } = require("@xunlei/universal-native-api");
            const infoRes = await macApi.call("openURL", {
              url: result.url,
              openWithSafari: true,
            });
          } else {
            const os = detectOS();
            if (os === "iOS") {
              window.location.href = result.url;
            } else {
              window.location.href = result.url;
              // window.open(result.url, "_blank", "noopener,noreferrer");
            }
          }
        } else {
          this.$message({
            type: "warning",
            message: "暂时无法查看资源，请稍后重试",
          });
        }
      } catch (e) {
        this.$message({
          type: "warning",
          message: "暂时无法查看资源，请稍后重试",
        });
      }
    },
  },
};
