import axios from "axios";
import { compileOpts } from "@/utils/util";
import { IClientTaskParams } from "@/types/common";

// 网盘资源唤起客户端取回
export function callClient11({
  files,
  userId,
  fromShare,
  shareId,
  passCodeToken,
  shareUserId,
  from,
  needLogin = false,
  selectNav,
}: IClientTaskParams) {
  return axios({
    method: "post",
    url: "http://127.0.0.1:28317/yun_fetch_back/",
    headers: { "content-type": "application/x-www-form-urlencoded" },
    data: {
      files,
      userId,
      fromShare,
      shareId,
      passCodeToken,
      shareUserId,
      from,
      needLogin,
      selectNav,
    },
    timeout: 3000,
  });
}

// 获取客户端版本
export function getClientVersion(options = {}) {
  let params = compileOpts({
    params: { ...options, from: "pc-web" },
  });
  return axios.get("http://127.0.0.1:28317/get_thunder_version/", {
    params,
    timeout: 3000,
  });
}
