import eventEmitter from "@/utils/eventEmitter";

export default {
  data() {
    return {
      macDirectDownloadClientVersion: "4.1.2",
      macDownloadUrl: "https://down.sandai.net/mac/thunder_xiaozhan.dmg",
    };
  },
  methods: {
    async createMacTasks(config: { downloadDir: string; tasks: any[] }) {
      if (config.tasks !== null && typeof config.tasks === "object") {
        if (config.tasks.length) {
          const linkStr =
            "thunders://" +
            JSON.stringify(config)
              .replace(/^\"|\"$/g, "")
              .replace(/\\/g, "");

          try {
            eventEmitter.emit("open-mac-download", 0, {
              files: config.tasks,
              links: linkStr,
            });
          } catch (error) {
            this.$message({
              type: "warning",
              message: "无法创建下载任务，请稍后重试",
            });
          }
        }
      } else {
        this.$message({
          type: "warning",
          message: "无法获取下载链接，请稍后重试",
        });
      }
    },
    handleMacDownload(urls: string) {
      setTimeout(function () {
        location.href = urls;
      }, 200);
    },
  },
};
