import { callClient11, getClientVersion } from "@/api/common";
import { IClientTaskParams } from "@/types/common";
import xlpc from "@/utils/xl-pc";

export default {
  data() {
    return {
      callClientProtocol:
        "thunderx://QUEtUnVuVGh1bmRlckFuZFF1aXQgLVN0YXJ0U291cmNlOlRodW5kZXJVbmlvbl9Qcm90b2NhbFpaCg==",
      clientDownloadUrl:
        "https://down.sandai.net/thunder11/XunLeiWebSetup_xiaozhan.exe", // 迅雷11下载地址
      clientClipBoardProtocol: "#$ThunderPanFetchBack:content$#", // 文件取回写入剪切板的协议
      retrieveClientVersion: "11.0.3", // 客户端支持云盘文件取回的版本
      directDownloadClientVersion: "11.2.6",
    };
  },
  methods: {
    async callClientRetrieve({
      files,
      userId,
      fromShare,
      shareId,
      passCodeToken,
      shareUserId,
      from,
      needLogin = false,
      selectNav,
    }: IClientTaskParams) {
      try {
        // 客户端内新增直接下载
        if (window.native) {
          const nativeVersion = await xlpc.getThunderVersion();
          console.log("nativeVersion res >>>", nativeVersion, this.directDownloadClientVersion);

          if (nativeVersion >= this.directDownloadClientVersion) {
            const dlParams = JSON.stringify({
              files,
              userId,
              fromShare,
              shareId,
              passCodeToken,
              shareUserId,
              from,
              needLogin,
              selectNav,
            });
            console.log("dl params >>>", dlParams);
            const dlRes = await xlpc.callDirectDownload(dlParams);

            console.log("callDirectDownload dlRes >>>", dlRes);

            if (dlRes.result === "success") {
              if (dlRes) {
                return dlRes;
              }
            }
          }
        }

        const clientVersionRes = await getClientVersion();
        if (clientVersionRes.data.ret !== 0) {
          throw new Error(`get_thunder_version: ${clientVersionRes.data.ret}`);
        }

        if (clientVersionRes.data.version < this.retrieveClientVersion) {
          return false;
        }

        this.$message({
          type: "success",
          message: "客户端唤起中，请稍等",
        });

        const callClientRes = await callClient11({
          files,
          userId,
          fromShare,
          shareId,
          passCodeToken,
          shareUserId,
          from,
          needLogin,
          selectNav,
        });
        if (callClientRes.data.ret === 0) {
          return {
            result: "success",
            code: callClientRes.data,
          };
        }
        return {
          result: "fail",
          code: callClientRes.data,
        };
      } catch (error) {
        this.$raven.error(error);
        // this.$message({
        //   type: "warning",
        //   message: "客户端调用失败",
        // });

        return {
          result: "fail",
          code: "",
        };
      }
    },
  },
};
